.submit-button {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 40px;
    background-color: #f71818;
    width: 250px;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px;
    border-radius: 8px;
}