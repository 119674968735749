body {
    font-family: "Avenir Next", Arial, sans-serif;
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    color: #33312F;
    background: linear-gradient(#ffffff, #f9bec7);
}

.weather-container {
    display: flex;
    flex-direction: column;
    width: 375px;
    height: 667px;
}

.weather-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding-top: 20px;
}

@media only screen and (min-width: 567px) {

    body {
        height: 100%;
    }

    .weather-container {
        width: 100vw;
        height: 100%;
    }

    .weather-wrapper {
        height: auto;
    }
}

@media only screen and (min-width: 767px) {

    .weather-wrapper {
        padding: 60px 30px 180px 30px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 1600px;
        align-items: stretch;
    }
}


@media only screen and (min-width: 1600px) {

    .weather-container {
        padding: 0 0 20px 0;
    }

    .weather-wrapper {
        width: 1600px;
        margin-left: auto;
        margin-right: auto;
        background: white;
        box-shadow: .25rem .5rem 1rem 0 rgba(0, 0, 0, 0.1);
    }
}