.switch-wrapper {
    height: 60px;
    min-width: 100%;
    background-color: #5a287f;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
}

.switch {
    opacity: 0;
}

.switch-btn {
    box-sizing: border-box;
    position: relative;
    outline: none;
    width: 4.6rem;
    height: 2rem;
    display: block;
    cursor: pointer;
    background: #747474;
    border-radius: 2rem;
    padding: 3px;
    transition: all .4s ease;
}


.switch-btn::after {
    position: relative;
    left: 0;
    display: block;
    content: "";
    width: 40%;
    height: 100%;
    border-radius: 50%;
    background: white;
    transition: all .2s ease;
}

.switch:checked + .switch-btn {
    background: #ff0a54;
}

.switch:checked + .switch-btn::after {
    left: 60%;
}

.switch-text {
    font-weight: 300;
    color: #f9bec7;
}