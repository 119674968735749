.counter {
    width: 316px;
    display: flex;
    justify-content: space-between;
    box-shadow: .25rem .5rem 1rem 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(51, 49, 47, 0.7);
    padding: 4px;
    color: #ffffff;
}

.name-description {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: space-between;
}

.name-description h3 {
    font-size: 18px;
}

.weather-left {
    display: flex;
    text-align: start;
    align-items: stretch;
}

.numbers {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    text-align: center;
    padding: 0 3px 3px 3px;
    row-gap: 2px;
}

.index {
    color: #5a287f;
    background-color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.weather-right {
    font-size: 14px;
    font-weight: normal;
    color: lightgrey;
    text-align: end;
    display: flex;
    margin-top: 6px;
    flex-direction: column;
    justify-content: space-between;
}

.total-points {
    background-color: #f9bec7;
    margin-top: 2px;
    font-size: 11px;
    color: #f71818;
    border-radius: 50%;
    padding: 4px;
    text-align: center;
}

.icon-weather {
    width: 44px;
    height: 44px;
}

.celsius {
    font-weight: 600;
}

.windkracht {
    font-size: 10px;
}