.region-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 100px;
    min-width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.region-ul {
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.region-li {
    margin: 8px 0;
}

.region-ul .region-li::before {
    background-color: rgba(51, 49, 47, 0.7);
    font-size: 22px;
    color: white;
}

.booster-bar {
    width: 100%;
}

a:link {
    text-decoration: none;
}

.web-right-region {
    display: none;
}

.footer-region {
    display: none;
}

.region-title {
    padding: 10px 0 0 0;
}

@media only screen and (min-width: 767px) {

    .region-wrapper {
        display: flex;
        align-items: stretch;
        position: static;
        flex-direction: row;
        justify-content: stretch;
        height: 100%;
        margin: 0 10px;
    }

    .region-ul {
        width: 45%;
        background-color: rgba(90, 40, 127, 0.4);
        border-radius: 32px 0 0 0;
        justify-content: flex-end;
    }

    .footer-region {
        display: flex;
    }

    .web-right-region {
        display: flex;
        min-height: 100%;
        width: 65%;
        justify-content: stretch;
    }

    .region-ul-web {
        list-style-type: none;
        padding: 10px 6px 0 6px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width: 1024px) {

    .region-wrapper {
        min-width: auto;
        max-width: 1600px;
        min-height: 500px;
        max-height: 800px;
    }

    .region-ul {
        width: 35%;
    }
}