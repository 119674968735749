.slider {
    position: relative;
    height: 74vh;
    width: 80vw;
    max-height: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 32px 32px 0 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slide {
    position: absolute;
    width: 70%;
    bottom: 0;
    left: 20px;
    right: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: .3s ease;
    flex-direction: column;
}

.slide.active {
    opacity: 1;
}

.slide h1, .slide h2 {
    font-size: 16px;
    position: static;
    color: #f9bec7;
    padding: 8px;
    margin: 0 0 5px 0;
    border-radius: 4px;
    background-color: #40463F;
}

.slider-button {
    font-weight: 800;
    border: none;
    font-size: 36px;
    color: #ff0a54;
    background: none;
}

.slider-button:disabled {
    color: #747474;
}

.gif {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

@media only screen and (min-width: 767px) {
    .slider {
        margin-left: auto;
        margin-right: auto;
        height: 500px;
        width: 300px;
    }

    .switch-bar {
        position: relative;
    }
}