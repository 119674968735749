.home {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100vw;
    text-align: center;
}

.map-home {
    margin-left: auto;
    margin-right: auto;
}

.web-home {
    display: none;
}

.home-wrapper {
    text-align: center;
    display: flex;
    height: auto;
    margin-bottom: 60px;
}

.pop-up {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/src/assets/images/friesland_map_gedraaid.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    margin-bottom: 14px;
    margin-top: 12px;
}

.welkom {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-bottom: 40px;
    align-items: center;
    background-color: rgba(90, 40, 127, 0.7);
    width: 80%;
    padding: 12px 20px;
    color: white;
    border: 3px solid #fff;
    box-shadow: .25rem .5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.welkom-text {
    font-size: 30px;
}

.intro {
    margin-top: 16px;
    font-size: 1.2rem;
}

.intro-2 {
    margin-top: 12px;
    font-size: 1rem;
}

.icon-terrace {
    margin-top: 80px;
    width: 24%;
    fill: #f71818;
}

.icon-star {
    color: #ff477e;
    font-size: 18px;
}

.info-button {
    width: 100vw;
    margin: 0;
    bottom: 60px;
    position: fixed;
    font-weight: 200;
}

.signup {
    z-index: 10;
    position: fixed;
    bottom: 60px;
}

@media only screen and (min-width: 767px) {

    .home {
        padding-top: 0;
        height: auto;
    }

    .home-title {
        display: none;
    }

    .web-home {
        display: flex;
        height: auto;
        width: 100%;
        justify-content: stretch;
        align-items: stretch;
    }

    .web-left {
        color: #33312F;
        width: auto;
        border-radius: 32px 0 0 0;
        display: flex;
        padding-top: 30px;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        background-color: rgba(90, 40, 127, 0.4);
    }

    .map-web-left {
        border-radius: 32px 0 0 0;
    }

    .login-web-home {
        margin-bottom: 0;
        height: 400px;
    }

    .footer-web-left {
        margin-top: 60px;
        width: 100%;
        margin-bottom: 0;
    }

    .web-right {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        justify-content: stretch;
    }

    .map-example {
        width: 450px;
        padding: 0;
        margin: 0;
        height: 400px;
    }

    .switch-web {
        margin-left: 40px;
        max-height: 500px;
        justify-content: center;
    }
}

@media only screen and (min-width: 1600px) {

    .web-left {
        min-width: 500px;
    }

    .login-web-home {
        min-width: 500px;
    }

    .map-example {
        width: 100%;
    }
}