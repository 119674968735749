@font-face {
    font-family: 'Core Sans G';
    src: url(coresansg-black-webfont.woff2) format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans G';
    src: url(coresansg-heavy-webfont.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans G';
    src: url(coresansg-extrabold-webfont.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans G';
    src: url(coresansg-bold-webfont.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans G';
    src: url(coresansg-light-webfont.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Core Sans G';
    src: url(coresansg-extralight-webfont.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}