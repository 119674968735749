.footer {
    padding: 8px 12px 0 12px;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    background-color: #181D17;
    width: 100%;
    position: fixed;
    bottom: 0;
    flex-shrink: 0;
    z-index: 20;
}

.footer-web {
    display: none;
}

.footer-icon {
    background-color: #181D17;
    color: #f9bec7;
}

.icon-bar {
    font-size: 34px;
    background-color: #40463F;
    fill: #f9bec7;
}

.icon {
    font-size: 34px;
    background-color: #181D17;
    fill: #f9bec7;
}

.icon > * {
    fill: #f9bec7;
    background-color: #181D17;
}

.icon > *:hover {
    fill: #747474;
}

#home-button > * {
    fill: #f71818;
}

#star-button > * {
    fill: #ff477e;
}

@media only screen and (min-width: 767px){

    .footer {
        display: none;
    }

    .footer-web{
        display: flex;
        width: 100%;
        height: 100px;
        position: fixed;
        bottom: 0;
        background-color: #181D17;
        color: #525651;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        z-index: 20;
    }
}