#star-button-text {
    vertical-align: bottom;
    font-size: 34px;
}

#star-button-text > * {
    fill: #ff477e;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 20px;
    background-color: rgba(51, 49, 47, 0.7);
    margin-left: 20px;
    border-radius: 0 32px 0 0;
    height: 100%;
    max-width: 100%;
}

.alinea-text {
    border-radius: 32px 32px 0 0;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    color: white;
    padding: 20px 10px;
    margin: 0 2.8em;
}

.alinea {
    font-size: 1.2em;
    text-align: left;
    font-weight: 300;
}

.text-link a {
    color: #ff0a54;
    font-weight: 600;
}

.text-link a:hover {
    color: #f9bec7;
}

#drie {
    margin-left: 100px;
    font-weight: 600;
    font-size: 1.4em;
}

.arrow {
    font-size: 30px;
}

.arrow > * {
    fill: #f71818;
    vertical-align: middle;
}

.text-h2 {
    font-size: 2.4em;
    font-variant: all-small-caps;
    color: #f9bec7;
    margin: 0 3.2em;
    padding-bottom: 10px;

}

@media only screen and (min-width: 767px) {

    .text-h2 {
        font-size: 1.4em;
        margin-bottom: 100px;
    }

    .logged-in-web {
    }

    .booster {
        width: 300px;
    }

    .slider-text {
        min-width: 450px;
    }
}

@media only screen and (min-width: 900px) {

    .text-h2 {
        font-size: 1.6em;
    }

    .slider-text {
        min-width: 650px;
    }
}

@media only screen and (min-width: 1200px) {

    .text {
        margin-left: 40px;
    }

    .text-h2 {
        font-size: 2.2em;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 1300px) {

    .text {
    }

    .text-h2 {
        font-size: 3em;
    }
}

@media only screen and (min-width: 1400px) {

    .text-h2 {
        font-size: 3.4em;
    }

    .alinea {
        font-size: 1.6em;
    }

    #drie {
        font-size: 1.6em;
    }

    .slider-text {
        min-width: 800px;
    }
}

@media only screen and (min-width: 1600px) {

    .slider-text {
        min-width: 1000px;
    }
}