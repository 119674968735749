@import './assets/fonts/fonts.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h2 {
    font-weight: normal;
    font-size: 2.2rem;
    color: #181D17;
    margin: 0 6px;
}

h3 {
    color: #f9bec7;
    font-weight: 600;
    font-size: 1.3rem;
    letter-spacing: 1px;
    font-variant: all-small-caps;
}
