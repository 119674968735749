#navbar {
    height: 60px;
    background-color: #ff0a54;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    z-index: 10;
}

.username {
    text-align: center;
    font-size: 24px;
    color: white;
}

.username a {
    text-decoration: none;
}

.refresh {
    font-size: 40px;
    text-decoration: none;
    color: #f9bec7;
    cursor: pointer;
}

.refresh-button {
    border: none;
    background: #ff0a54;
}

#menu {
    display: block;
    top: 60px;
    left: 0;
    width: 100px;
    position: absolute;
}

#menu-web {
    display: none;
}

.hamburger-icon {
    color: white;
    font-size: 2.8em;
}

#hamburger {
    display: none;
}

.menuClass {
    width: 150px;
    padding: 10px;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    display: block;
    border-top: 2px solid #333;
    background-color: white;
}

.menuClass:hover {
    background: #ff0a54;
    color: white;
}

@media only screen and (min-width: 767px) {

    .nav-wrapper {
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: baseline;
        justify-content: space-between;
    }

    #menu-web {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }

    .refresh-button {
        display: none;
    }

    #menu {
        display: none;
    }

    .menuClass {
        width: 100px;
        color: white;
        background-color: #ff0a54;
        border: none;
    }

    .menuClass:hover {
        border-bottom: 2px solid #333;
    }

    .hamburger-icon {
        display: none;
    }

    .exit {
        display: none;
    }
}

@media only screen and (min-width: 1600px) {

    .nav-wrapper {
        width: 1600px;
    }
}