.auth-wrapper{
    display: flex;
    flex-direction: column;
}

h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.form {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.submit-button {
    margin: 40px auto 10px auto;
    height: 40px;
    background-color: #f71818;
    min-width: 200px;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px;
    border-radius: 8px;
}

.submit-button-profile {
    margin-top: 40px;
}

.input {
    margin-bottom: 6px;
    width: 250px;
    height: 26px;
    padding: 4px;
    margin-left: auto;
    margin-right: auto;
}

.input-textarea {
    padding: 4px;
}

.submit-send {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 40px;
    background-color: #f71818;
    width: 250px;
    color: white;
    border: none;
    font-weight: bold;
    font-size: 18px;
    border-radius: 8px;
}