.map {
    width: 360px;
    height: 205px;
}

#friesland {
    width: 100%;
}

#noordwest {
    width: 52%;
    height: 46%;
    margin-top: -6px;
}

#zuidwest {
    width: 52%;
    height: 54%;
    margin-top: -6px;
}

#noordoost {
    width: 48%;
    height: 46%;
    margin-top: -6px;
}

#zuidoost {
    margin-top: -6px;
    width: 48%;
    height: 54%;
}

#wadden {
    width: 100%;
    margin-top: 6px;
    padding: 0;

}

@media only screen and (min-width: 767px) {

    .map {
        width: 400px;
        height: 272px;
        padding-bottom: 66px;
    }
}

@media only screen and (min-width: 900px) {

    .map {
        width: 430px;
        height: 290px;
    }
}

@media only screen and (min-width: 1024px) {

    .map {
        width: 460px;
        height: 320px;
    }
}

@media only screen and (min-width: 1400px) {

    .map {
        width: 500px;
        height: 340px;
        margin-bottom: 140px;
    }
}