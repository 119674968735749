.header {
    display: none;
}

@media only screen and (min-width: 767px) {

    .header {
        display: flex;
        margin: 0;
        padding: 160px 60px 0 60px;
        background: url("https://oostvlie.nl/wp-content/uploads/2017/06/tribe-horeca-interieur-strandtent-Oost_01.jpg") no-repeat top center fixed;
        width: 100%;
        max-height: 780px;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    .title-map {
        display: flex;
        max-height: 780px;
        flex-direction: row;
        margin-bottom: 40px;
    }

    .title {
        color: #FFF;
        font-size: 4rem;
        font-weight: 400;
        padding: 0 120px;
        text-align: right;
    }

    .screenshot {
        width: 260px;
        height: 350px;
        max-width: 260px;
        max-height: 350px;
        margin-right: 40px;
        border: 3px solid #fff;
        box-shadow: .25rem .5rem 1rem 0 rgba(0, 0, 0, 0.2);
    }
}

@media only screen and (min-width: 850px) {

    .title {
        font-size: 5.5rem;
    }
}

@media only screen and (min-width: 1400px) {

    .screenshot {
        width: 300px;
        height: 420px;
        max-width: 300px;
        max-height: 420px;
    }
}

@media only screen and (min-width: 1600px) {

    .header {
        height: 780px;
    }

    .screenshot {
        width: 300px;
        max-width: 300px;
        height: 495px;
        max-height: 495px;
        margin-right: 140px;
    }
}
