.booster {
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    position: fixed;
    bottom: 60px;
}

.options {
    min-width: 100%;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: stretch;
    background-color: #5a287f;
}

.booster-options {
    width: 100%;
    display: flex;
    align-self: center;
}

#temp, #clouds, #wind {
    display: none;
}

.clouds {
    color: #f71818;
}

.temp {
    color: #ff477e;
}

.wind {
    color: #747474;
}

.booster-button, .booster-button-checked {
    width: 100%;
    font-size: 34px;
    padding-top: 12px;
    height: 60px;
    border: 1px solid #40463F;
}

.booster-button-checked {
    background-color: #ff477e;
    color: #5a287f;
}

.clouds-checked, .temp-checked, .wind-checked {
    color: #f9bec7;
}

@media only screen and (min-width: 767px){

    .booster {
        width: 100%;
        position: static;
    }
}

